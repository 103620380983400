import APMS, { Sentry } from '@banking/web-apms';

const env = process.env.env || 'unknown';

const dsn =
  process.env.env === 'live'
    ? 'https://366a4c68235427748acec1dc094bf903@etracking.maribank.com.sg/sentry/17'
    : 'https://ec1d87a134a1d57d7fe88c0392644889@sentry-admin-nonlive.uat.npt.seabank.io/111';

const tag = process.env.BRANCH?.replace(/\//g, '-');

export const DEFAULT_OPTIONS: Sentry.BrowserOptions = {
  dsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  release: `${env}@${tag}`,
  environment: env,
  // debug: true,
  tracesSampleRate: 0.01,
  enableTracing: true,
};

export const initAPMS = (projectId: string) => {
  APMS.init({
    features: [
      {
        name: 'sentry',
        options: DEFAULT_OPTIONS,
      },
    ],
    projectId,
  });
};
